import moment from 'moment-timezone';

export default {
    methods: {
        /**
         * Prepare moment object with community timezone set (if present)
         *
         * @param input can be ISO8601 string, timestamp (milliseconds) or Date object
         * @param keepLocalTime pass true to have only the timezone (and offset) updated, keeping the local time same. Useful for date strings without time (e.g. 2022-10-23)
         * @param timezoneId string | undefined
         * @returns {moment.Moment}
         */
        parseDateTime(input, keepLocalTime = false, timezoneId) {
            const momentObj = moment(input);

            const timezone = timezoneId ?? this.$store?.getters['auth/community']?.timezoneId ?? null;

            return timezone ? momentObj.tz(timezone, keepLocalTime) : momentObj;
        },

        /**
         * Prepare datetime string with respect to community timezone
         *
         * @param input can be ISO8601 string, timestamp (milliseconds) or Date object
         * @param format moment.js formatting rule string
         * @param keepLocalTime pass true to have only the timezone (and offset) updated, keeping the local time same. Useful for date strings without time (e.g. 2022-10-23)
         * @returns {string}
         */
        formatDateTime(input, format = 'MM/DD/YYYY hh:mm a', keepLocalTime = false) {
            return this.parseDateTime(input, keepLocalTime).format(format);
        },
    },
};
